<template>
  <nav
    class="navbar is-primary is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="../assets/logo.png" />
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': data.activeMenu }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarBasicExample"
      class="navbar-menu"
      :class="{ 'is-active': data.activeMenu }"
    >
      <div class="navbar-start">
        <router-link
          class="navbar-item"
          :to="{
            hash: '#about',
          }"
        >
          Sobre
        </router-link>
        <router-link
          class="navbar-item"
          :to="{
            hash: '#whatwedo',
          }"
        >
          O que fazemos
        </router-link>
        <router-link
          class="navbar-item"
          :to="{
            hash: '#work',
          }"
        >
          Trabalho Social
        </router-link>
        <router-link
          class="navbar-item"
          :to="{
            hash: '#contact',
          }"
        >
          Contato
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "NavBar",
  setup() {
    const data = reactive({
      activeMenu: false,
    });

    const toggleMenu = () => {
      data.activeMenu = !data.activeMenu;
    };

    return {
      data,
      toggleMenu,
    };
  },
};
</script>
<style  scoped>
.navbar.is-light .navbar-start > a.navbar-item {
  color: #6b3398;
  font-size: 1.2rem;
}

.navbar.is-light .navbar-start > a.navbar-item:hover {
  background-color: #6b3398 !important;
  color: #fff;
}
</style>