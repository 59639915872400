import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './sass/mystyles.scss'
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import Oruga from '@oruga-ui/oruga-next'
import '@oruga-ui/oruga-next/dist/oruga.css'
import Maska from 'maska'

// importing AOS
import AOS from 'aos'
import 'aos/dist/aos.css'


createApp(App).use(router).use(store).use(Maska).use(Oruga, {
    iconPack: "fas",
    statusIcon: true,
    button: {
        rootClass: "button",
        outlinedClass: () => {
            return "is-outlined"; // Outline class does not dependent on variants
        },
        disabledClass: "btn-disabled",
        variantClass: "is-",
    },
    input: {
        inputClass: "input is-small"
    },
    pagination: {
        linkCurrentClass: "pagination-link is-current",
        linkClass: "pagination-link"
    },
}).use(AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false,
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
})).mount('#app')
