
import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

const getDefaultState = () => {
  return {}
}

const store = createStore({
  plugins: [createPersistedState()],
  state: getDefaultState(),
  mutations: {},
  actions: {
    
  },
  getters: {
    
  },
  modules: {
  }
});

export default store;
