<template>
  <div class="home">
    <section class="hero background-banner">
      <NavBar></NavBar>
      <div class="hero-body">
        <div class="container" data-aos="zoom-out" data-aos-delay="100">
          <div class="columns mt-5">
            <div class="column is-5 mb-4 mt-5">
              <p class="title mt-5 has-text-white has-text-left">
                Ajudamos através da tecnologia a melhorar a vida das pessoas.
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-5">
              <p class="subtitle has-text-white mt-5">
                Juntos, criamos produtos que inspiram.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="about"
      class="hero container is-widescreen mt-4 mb-6"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="hero-body mt-6">
        <div class="columns">
          <div class="column is-offset-one-fifth is-two-thirds">
            <h1 class="title has-text-primary has-text-center">Algohit</h1>
            <p class="has-text-center is-size-4 mb-4">
              A Algohit é uma empresa que concentra diversas startups.<br />
              Nossa estrutura nos permite atuar em diversos segmentos com
              relevância e eficiência.
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div class="card">
              <div class="card-content card-algohit">
                <i class="fas fa-bullseye fa-2x mb-3 has-text-primary"></i>
                <p class="title has-text-link has-text-center is-size-4">
                  Desafiando padrões
                </p>
                <p class="has-text-left is-size-6">
                  Nossa missão é através da tecnologia melhorar a vida das
                  pessoas. Potencializar o crescimento de startups embrionárias,
                  estruturá-las e inseri-las no mercado.
                </p>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="card">
              <div class="card-content card-algohit">
                <i class="fas fa-users fa-2x mb-3 has-text-primary"></i>
                <p class="title has-text-link has-text-center is-size-4">
                  Desenvolvendo pessoas
                </p>
                <p class="has-text-left is-size-6 mb-5">
                  Ser a maior empresa de tecnologia no Brasil, mudar a cultura
                  das pessoas através da educação e ensiná-las a acreditar em
                  seus sonhos.
                </p>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="card">
              <div class="card-content card-algohit">
                <i class="fas fa-rocket fa-2x mb-3 has-text-primary"></i>
                <p class="title has-text-link has-text-center is-size-4">
                  Definindo propósitos
                </p>
                <p class="has-text-left is-size-6 mb-5">
                  Somos obstinados a sonhar grande com foco no resultado, com
                  espírito empreendedor para transformar o impossível em
                  realidade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="whatwedo"
      class="hero mb-4 pd-6 pb-6 services"
      data-aos="fade-up"
      style="background-color: rgb(193 188 188 / 12%)"
    >
      <div class="container is-widescreen">
        <div class="hero-body mt-6">
          <div class="columns">
            <div class="column is-offset-one-fifth is-two-thirds">
              <h1 class="title has-text-primary has-text-center">
                O que fazemos
              </h1>
              <p class="has-text-center is-size-5 mb-4">
                Grandes soluções ganham vida com tecnologia, parcerias e
                estratégias.
              </p>
            </div>
          </div>
          <div class="columns is-multiline mt-6">
            <div class="column is-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <i class="fas fa-laptop-code fa-2x has-text-primary mb-1"></i>
                <p class="has-text-center has-text-link is-size-4">
                  TECNOLOGIA
                </p>
                <p class="has-text-center">
                  Desenvolvemos softwares com tecnologias mais utilizadas no
                  mercado. Criamos seu produto com segurança e escalável.
                </p>
              </div>
            </div>
            <div class="column is-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <i class="fas fa-cloud fa-2x has-text-primary mb-1"></i>
                <p class="has-text-center has-text-link is-size-4">Cloud</p>
                <p class="has-text-center">
                  Contruímos seus produtos diretamente na nuvem. Tenha seu
                  negócio escalável e com segurança.
                </p>
              </div>
            </div>
            <div class="column is-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <i class="fas fa-gamepad fa-2x has-text-primary mb-1"></i>
                <p class="has-text-center has-text-link is-size-4">Games</p>
                <p class="has-text-center">
                  Desenvolvemos os jogos da sua idéia até as telinhas.
                </p>
              </div>
            </div>
            <div class="column is-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <i class="far fa-handshake fa-2x has-text-primary mb-1"></i>
                <p class="has-text-center has-text-link is-size-4">Parcerias</p>
                <p class="has-text-center">
                  Ajudamos a sua startup com tecnologia para exponeciar seus
                  resultados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="work" class="hero container is-widescreen">
      <div class="hero-body mt-6 mb-6" data-aos="fade-up">
        <div class="columns">
          <div class="column is-offset-one-fifth is-two-thirds">
            <h1 class="title has-text-primary has-text-center">
              Trabalhos sociais
            </h1>
            <p class="has-text-center is-size-5 mb-4">
              Conheça os projetos sociais que tem nosso apoio
            </p>
          </div>
        </div>
        <div class="columns is-multiline is-justify-content-center">
          <!-- start card game -->
          <div class="column is-4">
            <img
              src="../assets/abraco.png"
              class="abraco"
              alt="Abraço no coração"
            />
            <p class="has-text-link is-size-6">Abraço no coração</p>
          </div>
          <!-- end card game -->
        </div>
      </div>
    </section>
    <section
      id="contact"
      class="hero pb-6"
      style="background-color: rgb(193 188 188 / 12%)"
    >
      <div class="hero-body mt-6 container is-widescreen" data-aos="fade-up">
        <h1 class="title has-text-primary has-text-center mb-4">Contato</h1>
        <div class="columns mt-6">
          <div class="column is-6">
            <div
              class="columns is-multiline"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="column is-12">
                <div class="card">
                  <div class="card-content">
                    <i
                      class="fas fa-map-marker-alt fa-2x mb-3 has-text-link"
                    ></i>
                    <p class="title has-text-grey has-text-center is-size-4">
                      Nosso Endereço
                    </p>
                    <p class="has-text-center is-size-6">
                      Rua xpto, 13 - Bairro - São José dos Campos
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="card">
                  <div class="card-content">
                    <i class="far fa-envelope fa-2x mb-3 has-text-link"></i>
                    <p class="title has-text-grey has-text-center is-size-4">
                      E-mail
                    </p>
                    <p class="has-text-center is-size-6">
                      contato@algohit.com.br
                    </p>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="card">
                  <div class="card-content">
                    <i class="fas fa-phone-alt fa-2x mb-3 has-text-link"></i>
                    <p class="title has-text-grey has-text-center is-size-4">
                      Telefone
                    </p>
                    <p class="has-text-center is-size-6">
                      <a
                        href="https://api.whatsapp.com/send?phone=5512974041474&amp;text=Olá, gostaria de saber sobre a Algohit."
                        target="_blank"
                        title="Enviar mensagem para Whatsapp"
                      >
                        <i
                          class="fab fa-whatsapp"
                          title="Whatsapp"
                          style="color: #06d755"
                        ></i>
                        (12) 97404-1474
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-6 has-text-left"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="card">
              <form v-on:submit="sendMessage">
                <div class="card-content">
                  <div class="field">
                    <div class="control">
                      <input
                        name="Nome"
                        required
                        v-model="name"
                        class="input is-medium"
                        type="text"
                        placeholder="Seu nome"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input
                        required
                        v-maska="'(##)#####-####'"
                        class="input is-medium"
                        v-model="celphone"
                        type="text"
                        placeholder="Seu telefone"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <textarea
                        required
                        name="Mensagem"
                        v-model="message"
                        class="textarea is-medium"
                        placeholder="Como podemos te ajudar?"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div class="control" style="padding-bottom: 10px">
                    <button type="submit" class="button is-link is-medium">
                      Enviar mensagem
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="container">
        <div class="columns">
          <div class="column is-6 has-text-left ml-6">
            <b>Endereço</b>
            <p>Rua xxx, 10 - São José dos Campos - SP</p>
            <p>E-mail: contato@algohit.com.br</p>
            <p>
              Telefone:
              <a
                href="https://api.whatsapp.com/send?phone=5512974041474&amp;text=Olá, gostaria de saber sobre a Algohit."
                target="_blank"
                title="Enviar mensagem para Whatsapp"
                class="has-text-white"
              >
                <i class="fab fa-whatsapp" title="Whatsapp"></i>
                (12) 97404-1474
              </a>
            </p>
          </div>
          <div class="column is-6">
            <b>Redes Sociais</b>
            <div class="social-media">
              <a
                href="https://facebook.com"
                target="_blank"
                class="button is-link mr-4"
                ><i class="fab fa-facebook-square"></i
              ></a>
              <a
                href="https://instagram.com"
                target="_blank"
                class="button is-link mr-4"
                ><i class="fab fa-instagram"></i
              ></a>
              <a
                href="https://twitter.com"
                target="_blank"
                class="button is-link"
                ><i class="fab fa-twitter"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="content has-text-centered">
        <p>© 2021 Algohit Soluções em Tecnologia.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref } from "vue";
import NavBar from "./NavBar.vue";
export default {
  name: "Landing",
  components: {
    NavBar,
  },
  setup() {
    const name = ref();
    const celphone = ref();
    const message = ref();

    const sendMessage = () => {
      window.open(
        `mailto:contato@algohit.com.br?subject=Contato Algohit&body=Nome:${name.value}%0D%0ACelular: ${celphone.value}%0D%0AMessage: ${message.value}`
      );
    };

    return {
      name,
      message,
      celphone,
      sendMessage,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background-banner {
  background-image: url("../assets/banner-5.jpg");
  height: 80vh;
}
.video-abraco {
  width: 100%;
  height: 500px;
}

.my-style-button {
  width: 100%;
  max-width: 900px;
  justify-content: center;
  font-size: 2.5rem !important;
}

.title {
  font-size: 3rem;
}
.subtitle {
  font-size: 1.7rem;
}
.is-shady {
  animation: flyintoright 0.4s backwards;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 0;
  border-radius: 4px;
  display: inline-block;
  margin: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
  border: 1px solid #ccc;
  font-family: "Poppins", sans-serif;
}
.card-content .content {
  padding: 15px;
}

.is-shady:hover {
  bottom: 3%;
  transition: all 0.3s 0.3s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}

.ico-card {
  max-height: 90px;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .my-style-button {
    width: 100%;
    max-width: 900px;
    justify-content: center;
    font-size: 1.5rem !important;
    white-space: normal;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .video-abraco {
    width: 100%;
    height: 300px;
  }

  .title {
    font-size: 2.2rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
}

.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 0 40px 0;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  text-align: center;
  border-bottom: 3px solid #fff;
}
.services .icon-box:hover {
  transform: translateY(-5px);
  border-color: #5dbabd;
}
.services .icon-box i {
  font-size: 60px !important;
  line-height: 1;
  margin-bottom: 15px;
  color: #ef7f4d;
}

/*

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: #111;
}
.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
} */

.abraco {
  width: 70%;
  height: auto;

  transition: 0.3s;
  /*
  opacity: 0.5;
  filter: grayscale(100);*/
}
/* .abraco:hover {
  opacity: 1;
  filter: none;
} */
.card-algohit {
  height: auto;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  /* CSS */
  .card-algohit {
    min-height: 316px;
  }

  .services .icon-box {
    min-height: 190px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .background-banner {
    background-image: url("../assets/banner-5.jpg");
    height: 50vh;
  }
}
</style>
